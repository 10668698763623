import React from "react"
import {CoursePageFull} from "../components/course-pages/CoursePageFull"
import { graphql, StaticQuery } from "gatsby"
import { faChartBar, faClock, faCode, faUser } from "@fortawesome/free-solid-svg-icons"
import { SHARED_COURSE_CONTENT } from "../components/shared/courses-text"

export default class CorsoAngularUI extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            angular_ui: file(relativePath: { eq: "courses/courses-logos/angular-ui.png" }) { ...thumb },

            carouselIntro1: file(relativePath: { eq: "courses/courses-carousel/angular-mastering-ui.png" }) { ...imgTrainingBig },

            review_1: file(relativePath: { eq: "courses/courses-reviews/angular-mastering-ui/review_angular_mastering_ui_10.png" }) { ...imgTrainingBig },
            review_2: file(relativePath: { eq: "courses/courses-reviews/angular-mastering-ui/review_angular_mastering_ui_11.png" }) { ...imgTrainingBig },
            review_3: file(relativePath: { eq: "courses/courses-reviews/angular-mastering-ui/review_angular_mastering_ui_12.png" }) { ...imgTrainingBig },
            review_4: file(relativePath: { eq: "courses/courses-reviews/angular-mastering-ui/review_angular_mastering_ui_13.png" }) { ...imgTrainingBig },


            mode1: file(relativePath: { eq: "courses/courses-mode-images/angular-ui-mode1.png" }) { ...imgMd },
            mode2: file(relativePath: { eq: "courses/courses-mode-images/angular-ui-mode2.png" }) { ...imgMd },
            mode3: file(relativePath: { eq: "courses/courses-mode-images/angular-ui-mode3.png" }) { ...imgMd },

            pricing_bg: file(relativePath: { eq: "courses/pricing/angular-mastering-ui.png" }) { ...imgMd },

          }
        `}
        render={data => <CoursePageFull {...this.props}  data={data} course={COURSE_INFO}/> }
      />
    )
  }
}

export const COURSE_INFO = {
  theme: {
    separatorHeaderColor1: '#4a90e2',
    separatorHeaderColor2: '#c3002f',
    separatorColor1: '#4a90e2',
    separatorColor2: '#dd0031',
    buttonColor: '#dd0031',
    panelBackground1: '#4a90e2',
    iconColor1: '#666',       // summary
    iconColor2: '#dd0031',    // faq
    footerVisible: true,
    navbarVisible: true,
  },
  header: {
    titleSEO: 'Corso Angular UI: components, directives, directive, dependency injection, moduli',
    title: 'ANGULAR <strong>MASTERING UI</strong>',
    shortTitle: 'ANGULAR <br /><strong>MASTERING UI</strong>',
    subtitle: 'Approfondimento sul framework: componenti, dependency injection, custom pipes, attribute & structural directives',
    image: 'angular_ui',
    difficulty: 'Avanzato',
    duration: '16 ore'
  },
  description: [
    { title: 'INTRODUZIONE', text: 'Due giornate di formazione in cui si analizzano metodologie, scenari e strumenti inclusi nel framework Angular, spesso poco noti, per padroneggiare la creazione di componenti custom, sfruttare al massimo il motore di dependency injection, creare custom pipes e direttive.' },
    /*    { title: 'DURATA', text: 'Il corso ha una durata variabile tra le 16 e le 24 ore, quindi 2 o 3 giornate: <br /><br />• 16 ore: il corso sarà intensivo, con un ritmo molto veloce e ci concentreremo sui contenuti <br/>• 24 ore: ritmo più lento, argomenti affrontati in modo più approfondito, gli studenti potrano eseguire alcune esercitazioni e configurare l\'ambiente di lavoro'},*/
    { title: 'OBIETTIVO DEL CORSO', text: 'Approfondire diversi costrutti e API del framework, solitamente poco utilizzati, allo scopo di sfruttarne tutto il potenziale, creando codice riutilizzabile, testabile ed efficiente.'},
    { title: 'REQUISITI', text: 'Conoscere le fondamenta del framework e/o aver partecipato ai corsi <a href="../corso-angular-core-concepts/">Angular Core Concepts</a> e <a href="../corso-angular-mastering/">Mastering Angular</a>.\n' +
        'E’ preferibile aver già creato in precedenza progetti Angular per trarre il massimo beneficio dal corso e apprezzarne i contenuti.' },
  ],
  metadata: [

  ],
  carousels: {
    main: [
      {img: 'carouselIntro1'},
      /*{img: 'carouselIntro1', desc: 'Un abuso della dependency injection porta spesso ad uno stato unpredictable'},*/

    ],
    reviews: [
      {img: 'review_1', desc: ''},
      {img: 'review_2', desc: ''},
      {img: 'review_3', desc: ''},
      {img: 'review_4', desc: ''},
    ],
  },
  summary: [
    { icon: faClock, label: 'Durata:', value: '16 ore' },
    { icon: faChartBar, label: 'Livello:', value: 'Avanzato' },
    { icon: faCode, label: 'Versione Angular:', value: '14.x' },
    { icon: faCode, label: 'Versione Typescript:', value: '4.x+' },
    { icon: faUser, label: 'Tipologia:', value: 'in aula o da remoto' },
  ],
  exampleLesson: {
    enabled: true,
    title: 'ESEMPIO LEZIONE',
    desc: 'In questo video la creazione di un custom pipe:',
    video1: '1fmPinDzZ_w',
    bgColor: '#4a90e2',
    items: [
      { text: 'Formattare dati con i pipe forniti dal framework'},
      { text: 'Creazione di un custom pipe'},
      { text: 'Utilizzare il decoratore @Pipe e l\'interfaccia PipeTrasform'},
    ]
  },
  mode: {
    colLeft: {
      img: 'mode1',
      title: 'TEORIA',
      text: 'Approfondimento su API, tecniche e funzionalità del framework. Decine di slide, diagrammi e materiale esclusivo incluso nel corso'

    },
    colCenter: {
      img: 'mode2',
      title: 'LIVE CODING',
      text: 'La maggior parte degli argomenti sono esposti tramite sessioni di live coding, affrontando scenari reali e creando codice riutilizzabile',

    },
    colRight: {
      img: 'mode3',
      title: 'SOURCE CODE',
      text: 'Al termine del corso avrete a disposizione decine di componenti, direttive, pipes, moduli e tecniche da riutilizzare in applicazioni reali',
    }
  },
  program: {
    colLeft: [
      { type: 'title', value: 'Custom Components' },
      { value: '@Input, @Output e Content (multiple) Projection' },
      { value: 'Component-based approchat e 1-way data flow architectures' },
      { value: 'stateless vs stateful components' },
      { value: 'Ciclo di vita dei componenti' },
      { value: 'Componenti riutilizzabili' },
      { value: 'Componenti compositi' },
      { value: 'Comunicazione tra componenti' },

      { type: 'title', value: 'Components Techniques' },
      { value: 'Local CSS e ViewEncapsulation' },
      { value: 'Gestione errori e messaggi eccezioni personalizzati' },
      { value: '"@ViewChildren" vs "@ContentChildren" decorator' },
      { value: 'Creare istanze di componenti a runtime, invece che dai template' },
      { value: '"ViewContainerRef" e "ComponentFactoryResolver"' },
      { value: 'Utilizzo dell’immutabilità' },
      { value: 'Ottimizzazione performance e strategie di ChangeDetection' },
      { value: 'Component providers e approfondimento dependency injection' },
      { value: 'Dependency Injection, configurazione provider nei componenti' },

      { type: 'title', value: 'Componenti riutilizzabili per UIKit' },
      { value: 'Grid e layout components' },
      { value: 'Typography, List, TabBar, Cards' },
      { value: 'Collapsable, Side Panel, Divider e molto altro' },
      { value: 'Accordion e componenti “compositi”' },
      { value: 'Integrazione 3rd party libraries / types' },
      { value: 'Mappe: integrazione Google Map API' },
      { value: 'Chart riutilizzabili: integrazione ChartJS' },
      { value: 'Creare custom modules per l\'organizzazione dei componenti' },


      { type: 'title', value: 'Mastering Dependency Injection' },
      { value: 'Creare servizi e routine riutilizzabili', },
      { value: 'Condividere dati tra componenti e views', },
      { value: '@Injectable e providedIn', },
      { value: 'Gerarchia degli injectors', },
      { value: '@Inject and useValue', },
      { value: 'InjectionToken', },
      { value: 'useClass vs useFactory vs useExisting', },
      { value: 'Gestione providers in moduli e componenti', },
      { value: 'utilizzo di forRoot() per i moduli “shared”', },
      { value: 'environments & dependency injection: development e production', },


      /*
            { type: 'title', value: 'Generare e pubblicare lo UIKit su NPM' },
            { value: 'Generazione librarie di componenti e servizi riutilizzabili' },
            { value: 'Pubblicazione della libreria su npm' },
            { value: 'Utilizzo e aggiornamento della libreria' },
            { value: 'Analisi del bundle size e composizione della libreria' },
            { value: 'Generazione automatica della documentazione' },
            { value: 'Documentation Coverage' },*/
/*

      { type: 'title', value: 'Introduzione e integrazione Unit Test' },
      { value: 'Unit Test vs E2E' },
      { value: 'Introduzione a Jasmine' },
      { value: 'Introduzione e configurazione Karma' },
      { value: 'Utilizzo di TestBed' },
      { value: 'Testing Components' },
      { value: 'Testing Services' },
      { value: 'Mocking dependencies' },
      { value: 'Spies' },
*/

/*
      { type: 'title', value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },
      { value: '' },*/

    ],
    colRight: [



      { type: 'title', value: 'Custom Pipes' },
      { value: 'Built-in formatter: "date", "number", "json"' },
      { value: 'Cicli su oggetti con "ngFor" e "keyvalue" pipe' },
      { value: '"ngIf...as" & "async" pipe' },
      { value: 'Creazione custom pipes' },
      { value: 'Pure vs Impure Pipes' },
      { value: 'Pipe di conversione di formato' },
      { value: 'Creare Pipe per filtrare elenchi di dati tramite "ngFor"' },
      { value: 'Pipe asincrone e richieste HTTP' },
      { value: 'Custom pipes per generare immagini' },
      { value: 'Custom pipes e 3rd party REST API' },

      { type: 'title', value: 'Attribute Custom Directives' },
      { value: 'Creare direttive per la manipolazione del DOM' },
      { value: 'Manipolazione DOM con "@HostBinding" decorator' },
      { value: 'Gestione eventi con "@HostListener" decorator' },
      { value: 'Utilizzare l’"@Input" decorator come setter e getter' },
      { value: 'Structural directives VS attribute directives' },
      { value: 'Il ciclo di vita di una direttiva' },
      { value: 'Creare direttive “non visuali”' },
      { value: 'Gestione layout responsive con le direttive' },
      { value: 'Integrazione 3rd party API' },
      { value: 'Animazioni CSS' },
      { value: 'Creare custom Form validator per i template-driven forms' },

      { type: 'title', value: 'Structural Custom Directives' },
      { value: 'Direttive strutturali: utilizzo del simbolo “asterisk” *' },
      { value: 'Utilizzo delle direttive per gestione sicurezza e ruoli utenti' },
      { value: 'DOM: ElementRef vs TemplateRef' },
      { value: 'Usare le direttive per creare istanze a runtime di components' },
      { value: '"ViewContainerRef", "ComponentResolveFactory", "createEmbeddedView"' },
      { value: 'Creare custom *repeater (alternativa a *ngFor)' },
      { value: 'Sfruttare le direttive per gestire autenticazione e ruolo utenti' },
      { value: 'Utilizzare RxJS e Observable all’interno di direttive' },



    ],
  },
  pricing: {
    price: null,
    priceOffer: null,
    priceOfferDescription: null,
    items: [
      { label: 'Corso di formazione on-site', extra: '16 ore'},
      { label: 'Centinaia di slides', extra: 'Accesso Area Riservata'},
      { label: 'Esercitazioni post-corso', extra: 'Accesso Area Riservata'},
      { label: 'Codice Sorgente', extra: 'Incluso'},
      { label: 'Attestato di partecipazione', extra: 'PDF formato A4'},
    ],
    button: {
      enabled: true,
      visible: true,
      buyLink: '/forms/contact-form?subject=1',
      buyText: 'RICHIEDI PREVENTIVO',
      target: '_self',
      scrollToID: ''
    },
    bg: 'pricing_bg',
  },
  newsletter: {
    enabled: true,
    campaignFormUrl: 'https://fabiobiondi.us16.list-manage.com/subscribe/post?u=2478e04b9171b207e77c6cbc0&amp;id=85573d8b21',
  },
  actionButtons: {
    main: {
      enabled: true,
      visible: true,
      buyLink: null,
      buyText: 'RICHIEDI PREVENTIVO',
      target: '_scrollTo',
      scrollToID: '#pricingPanel'

    }
  },
  faq: SHARED_COURSE_CONTENT.faq,
  videos: {
    teaser1: 'HdfmD3wzEWs',
    teaser1Text: `
Ciao,
mi chiamo Fabio Biondi, sono Google Developer Expert in web technologies e Angular.

<br /><br />
In questo corso analizzeremo tecniche, metodologie e API del framework Angular, spesso poco documentate,
per lo sviluppo di  componenti, direttive, pipes e un'utilizzo avanzato del motore di dependency injection.
<br /><br />
Per questo motivo, questo corso è infatti apprezzato soprattutto da coloro che già utilizzano Angular da un po' di tempo
ma desiderano un approfondimento su 4 dei pilastri fondamentli del framework:
<br /><br />
• il primo è la creazione di componenti custom: descriverò infatti in dettaglio ogni fase del ciclo di vita,
useremo le QueryList tramite decoratori ViewChildren e ContentChildren, creremo istanze di componenti a runtime,
vi mostrerò come sfruttare strategie di change detection per ottimizzare le performance, animations,
integrazione librerie terze parti e altro ancora creando componenti come accordion, chart,
google map, componenti di layout come griglie, collapsable panels e organizzandoli in moduli custom.
<br /><br />
• creeremo direttive custom, sia attribute che structural (ovvero direttive che hanno l'asterisco come prefisso),
per la manipolazione del DOM, la gestione della sicurezza, applicare styling, automatizzare processi e gestire eventi.
Ho notato che la direttiva custom è uno degli strumenti meno utilizzati e sottovalutati dagli sviluppatori Angular
ma se ci pensate, la maggior parte delle funzionalità del framework per la gestione della UI sono basate
su questo costrutto, basti pensare a ngIf, ngFor, ngClass, routerLink e così via.
<br /><br />
• custom pipes: formattare dati, filtrare elenchi con ngFor, creare pipe sincrone, asincrone, pure e impure.
<br /><br />
• ampio spazio sarà dato anche al motore di dependency injection, un altro argomento spesso sottovalutato e
 la cui padronanza vi permetterà, non solo di evitare diversi bug o comportamenti anomali generati
 soprattutto  quando si utilizzano moduli custom e lazy loading, ma anche di sfruttare il motore di
 injection per creare codice più scalabile e spesso più conciso.
 E' quindi fondamentale comprendere come funziona la gerarchia degli injector, comprendere l'utilizzo
 di InjectionToken, useClass, useExisting, useFactory, l'utilizzo di forRoot() (usatissimo in quasi
 tutte le librerie Angular di terze parti che già integrate nella vostra app).
<br /><br />

Creeremo e pubblicheremo inoltre una libreria di componenti e servizi su npm, allo scopo di creare uno uikit
di funzionalità riutilizzabili su più progetti, scriveremo alcuni unit test, organizzeremo le funzionalità
in moduli e molto altro.
<br /><br />
Gli argomenti sono tantissimi e vi consiglio di dare un'occhiata al programma dettagliato.
    `
  },
}
